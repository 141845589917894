import * as React from "react";
import { useState } from "react";

import * as styles from "./Searchbar.module.css";
import SearchIcon from "../../assets/search.svg";

type SearchbarProps = {
  onSubmit: (text: string) => void;
  className?: string;
};

const Searchbar = ({ onSubmit, className }: SearchbarProps) => {
  const [placeholderVisible, setPlaceholderVisible] = useState(true);
  const [text, setText] = useState("");

  const focusHandler = () => {
    setPlaceholderVisible(false);
  };

  const blurHandler = () => {
    if (text === "") setPlaceholderVisible(true);
  };

  const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setText(ev.target.value);
  };

  const submitHandler = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (text !== "") onSubmit(text);
  };

  return (
    <form onSubmit={submitHandler} className={className || ""}>
      {placeholderVisible && (
        <div className={styles.placeholder}>
          <SearchIcon />
          &nbsp;&nbsp;<h5>Cerca</h5>
        </div>
      )}
      <input
        type="text"
        className={styles.searchBar}
        onChange={changeHandler}
        onFocus={focusHandler}
        onBlur={blurHandler}
        value={text}
      />
    </form>
  );
};

export default Searchbar;
