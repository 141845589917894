import * as React from "react";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";

import Footer from "./Footer";
import Navbar from "./Navbar";
import * as styles from "./Layout.module.css";

type BreadcrumbProps = {
  crumbs: { location: string; crumbLabel: string }[];
  crumbLabel?: string;
};

type LayoutProps = {
  children: React.ReactNode;
  breadcrumbs?: BreadcrumbProps;
};

const Layout = ({ children, breadcrumbs }: LayoutProps) => {
  return (
    <div className={styles.body}>
      <header>
        <Navbar />
        {breadcrumbs && <Breadcrumb {...breadcrumbs} />}
      </header>

      <main className={styles.contents}>{children}</main>

      <footer className={styles.footer}>
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
