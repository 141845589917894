import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./Navbar.module.css";
import Searchbar from "../ui/Searchbar";

const Navbar = () => {
  const searchHandler = (searchQuery: string) => {
    // TODO: implement a real handler
    console.log(searchQuery);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.left}>
        <Link
          className={styles.link}
          activeClassName={styles.active}
          to="/chi-siamo"
        >
          Chi siamo
        </Link>

        <Link
          className={styles.link}
          activeClassName={styles.active}
          to="/prodotti"
        >
          I nostri prodotti
        </Link>

        <Link
          className={styles.link}
          activeClassName={styles.active}
          to="/consigli-di-pulito"
        >
          Consigli di pulito
        </Link>
      </div>

      <div className={styles.center}>
        <Link to="/">
          <StaticImage
            className={styles.logo}
            src="../../assets/logo.png"
            alt="Lysoform logo"
            placeholder="tracedSVG"
            height={95}
            width={95}
          />
        </Link>
      </div>

      <div className={styles.right}>
        <Link
          className={styles.link}
          activeClassName={styles.active}
          to="/il-nostro-impegno"
        >
          Il nostro impegno
        </Link>
        <Link
          className={styles.link}
          activeClassName={styles.active}
          to="/contattaci"
        >
          Contattaci
        </Link>

        <Searchbar className={styles.search} onSubmit={searchHandler} />
      </div>
    </nav>
  );
};

export default Navbar;
