import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./Footer.module.css";
import SitemapFooter from "./SitemapFooter";

const Footer = () => {
  return (
    <>
      <SitemapFooter />

      <div className={styles.footerStrip}>
        <Link to="/404" className={styles.footerItem}>
          Privacy Notice
        </Link>
        <Link to="/404" className={styles.footerItem}>
          Cookie Policy
        </Link>
        <Link to="/404" className={styles.footerItem}>
          Sitemap
        </Link>
      </div>

      <div className={styles.divider} />

      <div className={styles.footerStrip}>
        <Link to="/404" className={styles.footerItem}>
          legal / dati societari
        </Link>
      </div>
    </>
  );
};

export default Footer;
