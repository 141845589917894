import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./SitemapFooter.module.css";
import SitemapFooterButton from "./SitemapFooterButton";

const SitemapFooter = () => {
  return (
    <div className={styles.footerContent}>
      <div className={styles.footerContentColumn}>
        <StaticImage
          className={styles.logo}
          src="../../assets/logo.png"
          alt="Lysoform logo"
          height={95}
          width={95}
          placeholder="tracedSVG"
          style={{ pointerEvents: "none" }}
        />
      </div>

      <div className={styles.footerContentColumn}>
        <div className={styles.footerTextColumn}>
          <Link to="/chi-siamo" className={styles.link}>
            Chi siamo
          </Link>
        </div>

        <div className={styles.footerTextColumn}>
          <Link to="/prodotti" className={styles.link}>
            Prodotti
          </Link>
          <Link to="/prodotti/casa" className={styles.link}>
            Casa
          </Link>
          <Link to="/prodotti/bucato" className={styles.link}>
            Bucato
          </Link>
          <Link to="/prodotti/persona" className={styles.link}>
            Persona
          </Link>
        </div>

        <div className={styles.footerTextColumn}>
          <Link to="/il-nostro-impegno" className={styles.link}>
            Purpose
          </Link>
        </div>

        <div className={styles.footerTextColumn}>
          <Link to="/concorsi" className={styles.link}>
            Concorsi
          </Link>
        </div>

        <div className={styles.footerTextColumn}>
          <Link to="/contattaci" className={styles.link}>
            Contatti
          </Link>
        </div>
      </div>

      <div className={styles.footerActionsColumn}>
        <SitemapFooterButton to="/404">Numero verde</SitemapFooterButton>
      </div>
    </div>
  );
};

export default SitemapFooter;
