import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./SitemapFooterButton.module.css";

type SitemapFooterButtonProps = {
  children: React.ReactNode;
  to: string;
};

const SitemapFooterButton = ({ children, to }: SitemapFooterButtonProps) => {
  return (
    <Link className={styles.link} to={to}>
      {children}
    </Link>
  );
};

export default SitemapFooterButton;
